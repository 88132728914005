<template>
  <div class="landing-page__banar customer__landingPage">
    <div class="customer__landingPage--wrapper">
      <section class="hero__section">
        <v-row class="white--text align-center px-4 py-0">
          <v-col sm="6">
            <div class="right-side">
              <div class="hero__section--title">
                <h1 class="title mb-4 black--color">بوابة العملاء</h1>
              </div>
              <div class="hero__section--descp">
                <p class="descp mb-3">
                  يعمل برنامج بوابة العملاء كبوابة إلكترونية آمنة للشركة
                  وعملائها,
                  <br />
                  حيث يتم مشاركة معلومات إدارة الأملاك بطريقة أمنة و سهلة.
                </p>
              </div>
              <div class="hero__section--button">
                <v-btn
                  @click="scroll('why_customer_portal')"
                  type="button"
                  class="font-weight-bold pa-5 font-12 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--large"
                >
                  <span class="v-btn__content"> اكتشف المزيد </span>
                  <v-icon color="white">mdi-arrow-down</v-icon>
                </v-btn>
              </div>
            </div>
            <!-- right-side -->
          </v-col>
          <v-col sm="6">
            <div class="left-side">
              <div class="hero__img">
                <img :src="mainPic" alt="Hero Image" />
              </div>
              <!-- hero__img -->
            </div>
            <!-- left-side -->
          </v-col>
        </v-row>
      </section>

      <section class="why_customer_portal" id="why_customer_portal">
        <v-container>
          <v-row>
            <div>
              <h1 class="customer_portal_title">
                لماذا بوابة العملاء مميزه في هكتار بلس ؟
              </h1>
              <p class="customer_portal_des">
                يتيح لك حساب هكتار المجاني الوصول إلى كل هذا وأكثر
              </p>
            </div>
          </v-row>
          <v-row class="card_container">
            <v-col cols="3">
              <div class="card">
                <img src="@/assets/img/customer-landingPage/box-icon.svg" />
                <p class="card_title">سهولة التحكم</p>
                <p class="card_des">
                  لوحة تحكم سهلة مصممة لك<br />
                  لتلائم إحتياجاتك.
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="card">
                <img src="@/assets/img/customer-landingPage/sheild-icon.svg" />
                <p class="card_title">صحة بياناتك</p>
                <p class="card_des">
                  صورة كاملة لصحة بياناتك <br />العقارية وطريقة عرضها
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="card">
                <img
                  src="@/assets/img/customer-landingPage/calender-icon.svg"
                />
                <p class="card_title">متابعة للفواتيرك</p>
                <p class="card_des">
                  تمتع بجاهزية دائما بتصدير <br />فواتيرك الضريبية إلكتورنيا
                </p>
              </div>
            </v-col>
            <v-col cols="3">
              <div class="card">
                <img src="@/assets/img/customer-landingPage/message-icon.svg" />
                <p class="card_title">الدعم المتواصل</p>
                <p class="card_des">
                  فريق هكتار مخصص دائما<br />
                  لتلبية مشاكل العملاء
                </p>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </section>

      <section class="order_hectar">
        <v-container>
          <h1 class="order_hectar_title">
            هل مزود خدماتك العقارية يستخدم بوابة الأعمال من هكتار؟
          </h1>
          <p class="order_hectar_des">
            يمكنك دعوة الجهة العقارية التي تدير أعمالك للإستفادة من بوابة
            الأعمال المخصصة لها عن طريق إرسال معلومات التواصل الخاصة بها وسيقوم
            فريق هكتار بالتواصل معها
          </p>
          <div class="d-flex order_hectar_action">
            <v-btn
              type="button"
              class="font-weight-bold pa-5 font-16 v-size--large mx-auto"
              color="#2196F3"
              height="50"
              @click="dialogState = true"
            >
              <span class="v-btn__content">
                اطلب هكتار لمزود خدماتك العقارية
              </span>
            </v-btn>
          </div>
        </v-container>
      </section>
      <!-- why unique section ends  -->
      <section class="features__section" v-if="false">
        <div class="container">
          <v-carousel v-model="model" hide-delimiters :show-arrows="false">
            <v-carousel-item
              v-for="item in items"
              :key="item.src"
              reverse-transition="fade-transition"
              transition="fade-transition"
            >
              <v-row
                class="white--text align-center px-4 py-0 my-0 cursor-auto"
              >
                <v-col sm="6">
                  <div class="right__side">
                    <div class="features__section--title">
                      <h2 class="title mb-2 black--color">
                        مميزات بوابة العملاء للملاك و المستأجرين
                      </h2>
                    </div>
                    <div class="features__section--descp">
                      <p class="descp mb-0 black--color">
                        يُعَد برنامج بوابة العملاء المستند إلى السحابة مفيدًا
                        لكل
                      </p>
                      <p class="descp black--color">
                        من الشركات وعملائها من عدة جوانب :
                      </p>
                    </div>
                    <div class="slider--right--side">
                      <div class="slider--text--area">
                        <div class="slide--title pt-2">
                          <h4 class="title">
                            <img
                              :src="item.titleImage"
                              alt="Building Icon"
                              class="ml-1"
                            />
                            {{ item.title }}
                          </h4>
                        </div>
                        <!-- slide--title -->
                        <div class="slide--descp">
                          <p class="descp">
                            {{ item.description }}
                          </p>
                        </div>
                        <!-- slide--descp -->
                      </div>
                    </div>
                    <!-- slider--right--side -->
                  </div>
                  <!-- right-side -->
                </v-col>
                <v-col sm="6">
                  <div class="left-side">
                    <!-- <div class="hero__img">
                <img
                  src="@/assets/img/customer-landingPage/features.svg"
                  alt="Hero Image"
                />
              </div> -->
                    <!-- hero__img -->
                    <div class="features--slider">
                      <v-sheet height="100%" tile>
                        <img :src="item.src" alt="" height="100%" />
                      </v-sheet>
                      <!-- <v-row
                        class="fill-height"
                        align="center"
                        justify="center"
                      >
                        <div class="text-h2">Slide {{ i + 1 }}</div>
                      </v-row> -->
                    </div>
                  </div>
                  <!-- left-side -->
                </v-col>
              </v-row>
            </v-carousel-item>
          </v-carousel>
          <v-row class="slides--icons">
            <v-icon @click="model--" class="right--icon">
              mdi-chevron-right
            </v-icon>
            <v-icon @click="model++" class="left--icon">
              mdi-chevron-left
            </v-icon>
            <!-- <div v-for="(slideTexts) in slideText" :key="slideTexts">
                    {{ slideTexts }}
                  </div> -->
          </v-row>
        </div>
      </section>
      <!-- features__section ends -->
      <section class="partners__section text-center" v-if="false">
        <div class="container">
          <div class="partners__section--title">
            <h3 class="title">شركاء النجاح مستخدمي هكتار بلس</h3>
          </div>
          <!-- partners__section title  -->
          <div class="partners__logos">
            <div class="arkan-logo">
              <img
                src="@/assets/img/customer-landingPage/arkan-logo.svg"
                alt="Partner Arkan Logo"
              />
            </div>
            <!-- arkan-logo -->
            <div class="lavista-logo">
              <img
                src="@/assets/img/customer-landingPage/Lavista-logo.svg"
                alt="Partner Lavista Logo"
              />
            </div>
            <!-- lavista-logo -->
            <div class="zed-logo">
              <img
                src="@/assets/img/customer-landingPage/ZED-logo.svg"
                alt="Partner ZED Logo"
              />
            </div>
            <!-- zed-logo -->
            <div class="cityEdge-logo">
              <img
                src="@/assets/img/customer-landingPage/CityEdge-logo.svg"
                alt="Partner CityEdge Logo"
              />
            </div>
            <!-- cityEdge-logo -->
            <div class="thiqa-logo">
              <img
                src="@/assets/img/customer-landingPage/thiqa-logo.svg"
                alt="Partner Thiqa Logo"
              />
            </div>
            <!-- thiqa-logo -->
          </div>
          <!-- partners__logos -->
        </div>
      </section>
      <!-- partners__section ends -->
      <section class="soon__section" v-if="false">
        <v-card class="soon">
          <div class="recommendation__screen container">
            <v-row>
              <v-col>
                <div class="recommendation__screen--title">
                  <h3 class="title black--color">
                    رشح هكتار بلس لشركتك الان, لتتمتع بكل الخصائص الذي تريدها
                    لإدارة عقاراك بنجاح.
                  </h3>
                </div>
                <div class="recommendation__screen--btn">
                  <v-btn class="google py-6 px-8">
                    <v-img
                      :src="megaPhoneIcon"
                      class="ml-1"
                      style="width: 22px"
                    ></v-img>
                    <span class="mb-1">سجل الان</span>
                  </v-btn>
                </div>
              </v-col>
            </v-row>
          </div>
          <v-container class="my-10 mobile__mt__0" id="container">
            <div class="content" id="logo">
              <h1>قريبا يمكنك تحميل تطبيق هكتار بلس</h1>
              <p class="fw-400 font-14">يكون متاح علي</p>
              <div class="btns">
                <v-btn class="google mx-5 py-5 px-6">
                  <v-img :src="googleIcon" class="ml-1"></v-img>
                  <span class="mb-2">متجر جوجل </span>
                </v-btn>
                <v-btn class="apple mx-5 py-5 px-8">
                  <v-img :src="appleIcon" class="ml-1"></v-img>
                  <span class="mb-1">متجر أبل</span>
                </v-btn>
              </div>
            </div>
          </v-container>
        </v-card>
      </section>
      <!-- soon__section ends  -->
      <v-card v-if="false">
        <v-container class="footer">
          <v-row>
            <div class="d-flex align-center justify-between col">
              <div class="right--side">
                <div class="logo site--logo">
                  <div class="">
                    <v-img
                      :lazy-src="logo"
                      :src="logo"
                      contain
                      alt="Site Logo"
                      height="48"
                      width="107"
                    />
                  </div>
                </div>
              </div>
              <!-- right--side -->
              <div class="left--side">
                <div class="links">
                  <ul>
                    <li
                      v-for="(link, i) in footerLinks"
                      @click="handleActions(link.clickAction)"
                      class="mx-5"
                      :key="i"
                    >
                      <a :href="link.to" :target="link.target">{{
                        link.text
                      }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- left--side -->
            </div>
          </v-row>
        </v-container>
      </v-card>
      <appSection />
    </div>
    <!-- customer__landingPage--wrapper ends-->
    <recommendationModel
      :dialogState="dialogState"
      @closed-add-dialog="dialogState = false"
    />
  </div>
  <!-- customer__landingPage ends-->
</template>
<script>
import appSection from '@/components/landingPage/appSection'
import { smoothScroll } from '../../helpers/scroll.js'

export default {
  name: 'CustomerLandingPage',
  components: {
    appSection,
    recommendationModel: () =>
      import('@/views/landingPage-customer/recommendationModel')
  },
  data() {
    return {
      dialogState: false,
      mainPic: require('@/assets/img/customer-landingPage/mainPic.png'),
      e2: 'اكتشف المزيد',
      states: ['Alabama', 'Alaska', 'American Samoa', 'Arizona'],
      appleIcon: require('@/assets/img/svgs/appleIcon.svg'),
      googleIcon: require('@/assets/img/svgs/googleIcon.svg'),
      megaPhoneIcon: require('@/assets/img/customer-landingPage/megaphone-icon.svg'),
      buildingIcon: require('@/assets/img/customer-landingPage/buildings-icon.svg'),
      footerLogo: require('@/assets/img/svgs/footerLogo.svg'),
      featuresImg: require('@/assets/img/customer-landingPage/features.svg'),
      links: [
        {
          title: 'المميزات',
          to: ''
        },
        {
          title: 'تواصل مع المبيعات',
          to: ''
        },
        {
          title: 'الباقات',
          to: '#bundels'
        },
        {
          title: 'مركز المساعده',
          to: '/help-center'
        },
        {
          title: 'هكتار',
          to: ''
        }
      ],
      footerLinks: [
        {
          text: 'المميزات',
          to: '#adv'
        },
        {
          text: 'تواصل مع المبيعات',
          target: '_blank'
        },
        {
          text: 'التسعير',
          to: '#bundels'
        },
        {
          text: 'مركز المساعده',
          to: 'help-center',
          target: '_blank'
        },
        {
          text: 'سياسة الاستخدام والخصوصية',
          to: '#'
        }
      ],
      items: [
        {
          src: 'https://i.postimg.cc/y6TJFQCc/s.png',
          title: '1. إدارة العقارات',
          titleImage: require('@/assets/img/customer-landingPage/buildings-icon.svg'),
          description:
            'هناك حقيقة مثبتة منذ زمن طويل وهي أن المحتوى المقروء لصفحة ما سيلهي القارئ عن التركيز على الشكل الخارجي للنص أو شكل توضع الفقرات في الصفحة التي يقرأها. ولذلك يتم استخدام طريقة لوريم إيبسوم لأنها تعطي توزيعاَ طبيعياَ -إلى حد ما- للأحرف عوضاً عن استخدام'
        },
        {
          src: 'https://i.postimg.cc/3R27GDxY/3-commercial-services.png',
          title: '2. إدارة المهام',
          titleImage: require('@/assets/img/customer-landingPage/buildings-icon.svg'),
          description:
            ' مما يجعله أكثر من 2000 عام في القدم. قام البروفيسور "ريتشارد ماك لينتوك" (Richard McClintock) وهو بروفيسور اللغة اللاتينية في جامعة هامبدن-سيدني في فيرجينيا بالبحث عن أصول كلمة لاتينية غامضة في نص لوريم إيبسوم وهي "consectetur"، وخلال تتبعه لهذه الكلمة في الأدب اللاتيني اكتشف المصدر الغير قابل للشك.'
        },
        {
          src: 'https://i.postimg.cc/J0qL6VXF/4-Residentail-services.png',
          title: '3. إدارة السندات',
          titleImage: require('@/assets/img/customer-landingPage/buildings-icon.svg'),
          description:
            'هنالك العديد من الأنواع المتوفرة لنصوص لوريم إيبسوم، ولكن الغالبية تم تعديلها بشكل ما عبر إدخال بعض النوادر أو الكلمات العشوائية إلى النص. إن كنت تريد أن تستخدم نص لوريم إيبسوم ما، عليك أن تتحقق أولاً أن ليس هناك أي كلمات أو عبارات محرجة أو غير لائقة مخبأة في هذا النص.'
        },
        {
          src: 'https://i.postimg.cc/9QzhpkQx/Home-1-2.png',
          title: '4. إدارة العقود',
          titleImage: require('@/assets/img/customer-landingPage/buildings-icon.svg'),
          description:
            'خلافاَ للإعتقاد السائد فإن لوريم إيبسوم ليس نصاَ عشوائياً، بل إن له جذور في الأدب اللاتيني الكلاسيكي منذ العام 45 قبل الميلاد، مما يجعله أكثر من 2000 عام في القدم. قام البروفيسور "ريتشارد ماك لينتوك" (Richard McClintock) وهو بروفيسور اللغة اللاتينية في جامعة هامبدن-سيدني في فيرجينيا بالبحث عن أصول كلمة لاتينية غامضة في نص لوريم إيبسوم وهي "consectetur"، وخلال تتبعه لهذه الكلمة في الأدب اللاتيني اكتشف المصدر الغير قابل للشك.'
        }
      ],
      slideText: ['primary', 'secondary', 'yellow darken-2', 'red', 'orange'],
      model: 0
    }
  },
  methods: {
    scroll(id) {
      return smoothScroll(id)
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../styles/customer-landingPage/customer-landingPage.scss';
</style>
