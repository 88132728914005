<template>
  <div class="bgBody">
    <!---------Banar------------->
    <!-- <Banar :isLoggedIn="isLoggedIn" /> -->

    <!--Header Section-->
    <headerSection />

    <!--partners Section-->
    <PartnersSection />

    <!--businessFeautes Section-->
    <FeautesSection />

    <!--businessFeautes Section-->
    <FeautesSliderSection />

    <div class="bundles-container">
      <v-container class="section-1 section-4" id="bundels">
        <Bundles
          :preview="true"
          ignoreBundle="الباقة المؤسسية"
          @openSalesContactDialog="handleActions('openAddDialog')"
        />
        <div class="banner">
          <p class="banner-title my-auto">
            تواصل معنا الآن لنسخة مؤسسية خاصة تناسب الجهات العقارية الكبيرة و
            الضخمة
          </p>
          <v-btn @click="openAddDialog" class="d-flex rounded-15" height="50">
            <span>تواصل مع المبيعات</span>
          </v-btn>
        </div>
      </v-container>
    </div>

    <appSection />

    <!----------------------------->
    <!-- ask-for-trial-copy-modal-->
    <!----------------------------->

    <contactSalesModel
      v-if="dialogState"
      :dialogState="dialogState"
      @changeDialogState="dialogState = $event"
      @closed-add-dialog="dialogState = false"
    />
  </div>
</template>

<script>
import Bundles from '@/components/env/bundles.vue'
import appSection from '@/components/landingPage/appSection'
import FeautesSection from '@/components/landingPage/FeautesSection'
import FeautesSliderSection from '@/components/landingPage/FeautesSliderSection'
import headerSection from '@/components/landingPage/headerSection'
import PartnersSection from '@/components/landingPage/PartnersSection'
import sanitizers from '@/mixins/sanitizers.js'
import { mapMutations } from 'vuex'

export default {
  mixins: [sanitizers],
  components: {
    contactSalesModel: () =>
      import('@/components/modals/contactSalesModel.vue'),
    headerSection,
    PartnersSection,
    FeautesSection,
    FeautesSliderSection,
    Bundles,
    appSection
  },
  data: () => ({
    leftArrow: require('@/assets/img/svgs/roundedArrow.svg'),
    rightArrow: require('@/assets/img/svgs/rightArr.svg'),
    dialogState: false
  }),
  methods: {
    ...mapMutations('appState', ['addNotification']),
    ...mapMutations('userState', ['setValidePhonenumber', 'setActiveLignTab']),
    handleActions(action) {
      switch (action) {
        case 'openAddDialog':
          return this.openAddDialog()
        default:
          return false
      }
    },
    openAddDialog() {
      this.dialogState = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/material-dashboard/_landing-page.scss';
</style>
